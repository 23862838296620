import React from "react"
import styled from "styled-components"
import { theme } from "../../styles/theme"
import { graphql, navigate } from "gatsby"
import { ArticleHomePage } from "@rily/components"

const Container = styled.div`
  background-color: ${theme.colors.floralWhite};
  padding: 0px 2%;
`

const ArticleHomepage = ({ data }) => {
  const filter = category => {
    navigate(`all?category=${category}`)
  }
  return (
    <Container>
      <ArticleHomePage
        data={data.allArticleHomePage.edges[0].node}
        onFilter={filter}
      />
    </Container>
  )
}

export default ArticleHomepage

export const query = graphql`
  query getHomepage {
    allArticleHomePage {
      edges {
        node {
          id
          Section1 {
            id
            __typename
            Feature {
              id
              __typename
              article {
                category
                id
                created_at
                updated_at
                title
                subtitle
                author {
                  id
                  name
                  authorId
                }
                published_at
                header {
                  id
                  url
                  provider
                  alternativeText
                  caption
                  width
                  height
                  hash
                }
              }
            }
            Card1 {
              id
              __typename
              article {
                category
                id
                created_at
                updated_at
                title
                subtitle
                author {
                  id
                  name
                  authorId
                }
                published_at
                header {
                  id
                  url
                  provider
                  alternativeText
                  caption
                  width
                  height
                  hash
                }
              }
            }
            Card2 {
              id
              __typename
              article {
                category
                id
                created_at
                updated_at
                title
                subtitle
                author {
                  id
                  name
                  authorId
                }
                published_at
                header {
                  id
                  url
                  provider
                  alternativeText
                  caption
                  width
                  height
                  hash
                }
              }
            }
            Card3 {
              id
              __typename
              article {
                category
                id
                created_at
                updated_at
                title
                subtitle
                author {
                  id
                  name
                  authorId
                }
                published_at
                header {
                  id
                  url
                  provider
                  alternativeText
                  caption
                  width
                  height
                  hash
                }
              }
            }
          }
          Section2 {
            id
            __typename
            Feature {
              id
              __typename
              article {
                category
                id
                created_at
                updated_at
                title
                subtitle
                author {
                  id
                  name
                  authorId
                }
                published_at
                header {
                  id
                  url
                  provider
                  alternativeText
                  caption
                  width
                  height
                  hash
                }
              }
            }
            Card1 {
              id
              __typename
              article {
                category
                id
                created_at
                updated_at
                title
                subtitle
                author {
                  id
                  name
                  authorId
                }
                published_at
                header {
                  id
                  url
                  provider
                  alternativeText
                  caption
                  width
                  height
                  hash
                }
              }
            }
            Card2 {
              id
              __typename
              article {
                category
                id
                created_at
                updated_at
                title
                subtitle
                author {
                  id
                  name
                  authorId
                }
                published_at
                header {
                  id
                  url
                  provider
                  alternativeText
                  caption
                  width
                  height
                  hash
                }
              }
            }
            Card3 {
              id
              __typename
              article {
                category
                id
                created_at
                updated_at
                title
                subtitle
                author {
                  id
                  name
                  authorId
                }
                published_at
                header {
                  id
                  url
                  provider
                  alternativeText
                  caption
                  width
                  height
                  hash
                }
              }
            }
          }
          Section3 {
            id
            __typename
            Feature {
              id
              __typename
              article {
                category
                id
                created_at
                updated_at
                title
                subtitle
                author {
                  id
                  name
                  authorId
                }
                published_at
                header {
                  id
                  url
                  provider
                  alternativeText
                  caption
                  width
                  height
                  hash
                }
              }
            }
            Card1 {
              id
              __typename
              article {
                category
                id
                created_at
                updated_at
                title
                subtitle
                author {
                  id
                  name
                  authorId
                }
                published_at
                header {
                  id
                  url
                  provider
                  alternativeText
                  caption
                  width
                  height
                  hash
                }
              }
            }
            Card2 {
              id
              __typename
              article {
                category
                id
                created_at
                updated_at
                title
                subtitle
                author {
                  id
                  name
                  authorId
                }
                published_at
                header {
                  id
                  url
                  provider
                  alternativeText
                  caption
                  width
                  height
                  hash
                }
              }
            }
            Card3 {
              id
              __typename
              article {
                category
                id
                created_at
                updated_at
                title
                subtitle
                author {
                  id
                  name
                  authorId
                }
                published_at
                header {
                  id
                  url
                  provider
                  alternativeText
                  caption
                  width
                  height
                  hash
                }
              }
            }
          }
        }
      }
    }
  }
`
